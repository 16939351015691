/* RewardsComponentStyles.css */

.claimArea {

    border-color: var(--clr-blue);
    color: var(--clr-dark);
    align-items: center;
    align-content: center;
    text-align: center;
    height: 100%;
  }
  
  .totalArea {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjust the minmax min value as needed */
    row-gap: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 16px;
    max-width: 50%; /* Full width of the container */ 
    margin: auto;
  }
  
  
  .button-group {
    display: flex;
    justify-content: space-around; /* This will put some space between your buttons */
    padding: 10px 0; /* Gives some space above and below the button group */
  }
  
  .button-group button {
    flex: 1; /* This will make sure each button takes up equal space */
    margin: 0 5px; /* This will ensure there's a little space between the buttons */
  }
  
  
  /* Styling for each reward section */
  .mint-claim {
    display: flex;
    
    border: 3px solid transparent;
    color: white;
    box-shadow: 0 0 0 1px var(--clr-blue);
    border-radius: 25px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin: auto;
    align-items: center;
    padding: 10px;
    overflow: hidden;flex: 0 0 auto; /* Do not grow, do not shrink, and is auto based on content size */
    width: 250px; /* Set a fixed width for each card, adjust as needed */
    /* fixed width */
    /* fixed height, adjust as needed */
    flex-direction: column;
    background-color: black;
    height:300px;
  }
  
  /* Styling for each reward section */
  .mint {
    display: flex;
    
    border: 3px solid transparent;
    color: white;
    box-shadow: 0 0 0 1px var(--clr-blue);
    border-radius: 25px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin: auto;
    align-items: center;
    padding: 10px;
    overflow: hidden;flex: 0 0 auto; /* Do not grow, do not shrink, and is auto based on content size */
    width: 500px; /* Set a fixed width for each card, adjust as needed */
    /* fixed width */
    /* fixed height, adjust as needed */
    flex-direction: column;
    background-color: black;
  }
  
  .image_claim {
    margin: auto;
    width: 100%;
    /* takes full width of the container */
    /* maintains the aspect ratio */
    object-fit: cover;
    /* covers the area, can be changed to contain to avoid cropping */
  }
  
  
  
  .mint img {
    margin: auto;
    width: 100%;
    /* takes full width of the container */
    height: auto;
    /* maintains the aspect ratio */
    object-fit: cover;
    /* covers the area, can be changed to contain to avoid cropping */
  }
  
  .imageTier {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    margin: auto;
    padding-bottom: 15px;
  
  }
  
  /* Adjustments for smaller screens */
  @media screen and (max-width: 768px) {
  
    .totalArea {
      flex-direction: column;
    }
  }
  
  @media (max-width: 599px) {
    .totalArea {
      grid-template-columns: repeat(minmax(120px, 1fr));
      /* even smaller min-width for mobile screens */
    }
  }
  
  /* Rest of your styles remain unchanged */
  .header {
    color: var(--clr-blue);
    text-align: center;
    margin: auto;
    font-size: 32px;
    padding-bottom: 10px;
  }
  
  .big-header {
    color: var(--clr-blue);
    text-align: center;
    margin: auto;
    font-size: 48px;
    padding-bottom: 10px;
  }
  
  .text-header {
    font-weight: bold;
    font-size: 30px;
    margin-top: 15px;
    margin-bottom: 5px;
    color: var(--clr-blue);
  }
  
  .text-body {
    font-weight: bold;
    font-size: 20px;
    color: var(--clr-dark);
  }
  
  .mint_button {
    width: 150px;
    height: 60px;
    background:
      linear-gradient(black 0 0) padding-box,
      linear-gradient(to top, white, var(--clr-blue)) border-box;
    border: 3px solid transparent;
    color: white;
    box-shadow: 0 0 0 1px var(--clr-blue);
    border-radius: 20px;
    font-family: "Play";
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    transition: all 250ms ease;
    margin: auto;
    margin-top: 20px;
  }
  
  .mint_button:hover {
    background-color: white;
    color: var(--clr-blue);
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  
  }
  
  
  .modal-content {
    max-height: 90vh; /* Sets a maximum height for the modal content */
    overflow-y: auto; /* Enables vertical scrolling if content exceeds the max-height */
    padding: 20px; /* Adds some spacing inside the modal content */
  
    background-color: black;
    max-width: 400px;
    margin: 0 auto;
    padding: 40px;
    border: 2px solid var(--clr-main);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-width: 3px;
    background:
      linear-gradient(black 0 0) padding-box,
      linear-gradient(to top, white, var(--clr-blue)) border-box;
    border: 3px solid transparent;
    color: white;
    box-shadow: 0 0 0 1px var(--clr-blue);
    border-radius: 20px;
  }
  
  .modal-content img {
    max-width: 50vh;
  }