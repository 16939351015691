.nft-container {
    padding: 20px;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 600px;
    height: auto;
    flex-direction: column;
    margin: auto;
  }
  
  .nft-container img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  .nft-container video {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }