@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Play:wght@400;700&display=swap');

.input[type="number"]::-webkit-inner-spin-button,
.input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Mozilla Firefox */
.input[type="number"] {
  -moz-appearance: textfield;
}

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color:#000000;
}

.background {
  background-image: url("./background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed; /* This will fix the background in place */
  height: 100vh; /* Adjust this value according to your design requirements */
  width: 100vw; /* Ensures full width is covered */
  overflow: auto; /* Enables scrolling within the div if content overflows */
}

.background-halloween {
  background-image: url("./Christmas.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed; /* This will fix the background in place */
  height: 100vh; /* Adjust this value according to your design requirements */
  width: 100vw; /* Ensures full width is covered */
  overflow: auto; /* Enables scrolling within the div if content overflows */
}

:root {
  --clr-white: #FFFFFF;
  --clr-black: #202020;
  --clr-dark: #ffffff;
  --clr-darker: #ffffff;
  --clr-green: #052001;
  --clr-blue: #4af8ff;
}

body {
  margin: 0;
  font-family: "Play";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ------------------------------------------------------ */
/* -- NAVIGATION -- */

nav {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));  align-items: center;
}

.navHeader {
  padding-bottom:10px;
}

.nav_links{
  align-items: center;
  justify-content: center;
  flex-wrap:wrap;
  margin: auto;
  text-align: center;
}

.nav_links_sidebar {
  margin-top: 10px;
  padding-top: 60px;
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.nav_buttonlayout{
  display: flex;
  align-items: center;
  margin-left: 10px;
  justify-content: space-between;
  

}

.nav_button{
  width: 150px;
  height: 60px;
  margin: auto;

  color: white;

  background:
    linear-gradient(black 0 0) padding-box,
    linear-gradient(to top, white, var(--clr-blue)) border-box;
  border: 3px solid transparent;
  color: white;
  box-shadow: 0 0 0 1px var(--clr-blue);
  border-radius: 20px;

  font-family: "Play";
  font-size: 20px;
  font-weight: 600;

  cursor: pointer;
  transition: all 250ms ease;
  
  margin: auto;
}

.navlinks_button{
  width: 150px;
  height: 60px;
  margin: auto;

  color: white;

  background:
    linear-gradient(black 0 0) padding-box,
    linear-gradient(to top, white, var(--clr-blue)) border-box;
  border: 3px solid transparent;
  color: white;
  box-shadow: 0 0 0 1px var(--clr-blue);
  border-radius: 20px;

  font-family: "Play";
  font-size: 20px;
  font-weight: 600;

  cursor: pointer;
  transition: all 250ms ease;
  
  margin: auto;
  margin-bottom: 10px;
}

.nav__brand {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: colu;
}

.nav__brand h1 {
  color: var(--clr-dark);
  font-size: clamp(1.75em, 4vw, 2.5em);
  font-weight: 800;
}

.nav__connect {
  width: 150px;
  height: 60px;
  margin: auto;

  color: white;

  background:
    linear-gradient(black 0 0) padding-box,
    linear-gradient(to top, white, var(--clr-blue)) border-box;
  border: 3px solid transparent;
  color: white;
  box-shadow: 0 0 0 1px var(--clr-blue);
  border-radius: 20px;

  font-family: "Play";
  font-size: 20px;
  font-weight: 600;

  cursor: pointer;
  transition: all 250ms ease;
  
  margin: auto;
}

.nav__connect:hover {
  background-color: white;
  color: var(--clr-blue);
}

.dropzone {

  grid-column: 1 / -1;
  width: 300px;
  height: 200px;
  border: 2px dashed #cccccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: auto;
  margin-top: 10px;
  cursor: pointer;
  color: #cccccc;
  font-size: 16px;
  border-radius: 40px;
}

.dropzone.active {
  background-color: #e9ecef;
}

.dropzone.dragover {
  color: #000000;
  border-color: #000000;
}

.button-container {
  display: flex;
  justify-content: center; /* or use space-between or center */
  align-items: center;
  flex-wrap: wrap;
}

.mint_button {
  width: 150px;
  height: 60px;
  margin: auto;

  color: white;

  background:
    linear-gradient(black 0 0) padding-box,
    linear-gradient(to top, white, var(--clr-blue)) border-box;
  border: 3px solid transparent;
  color: white;
  box-shadow: 0 0 0 1px var(--clr-blue);
  border-radius: 20px;

  font-family: "Play";
  font-size: 20px;
  font-weight: 600;

  cursor: pointer;
  transition: all 250ms ease;
  
}

.center-button {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
}

.mint_button-halloween {
  width: 150px;
  height: 60px;
  margin: auto;

  color: white;

  background:
    linear-gradient(red 0 0) padding-box,
    linear-gradient(to top, white, rgb(255, 36, 36)) border-box;
  border: 3px solid transparent;
  color: white;
  box-shadow: 0 0 0 1px rgb(255, 0, 0);
  border-radius: 20px;

  font-family: "Play";
  font-size: 20px;
  font-weight: 600;

  cursor: pointer;
  transition: all 250ms ease;
}

.mint_button:hover {
  background-color: white;
  color: var(--clr-blue);
}

.nav__connect_sidebar {
  width: auto;
  height: 50px;
  margin: 0 auto;

  background-color: var(--clr-dark);
  color: var(--clr-white);

  border: none;
  border-radius: 4px;

  font-family: "Play";
  font-size: 1.10em;
  font-weight: 600;

  cursor: pointer;
  transition: all 250ms ease;
  padding-left: 10px;
  padding-right: 10px;
}

.nav__connect_sidebar:hover {
  background-color: var(--clr-darker);
}

.nav__wallet {
  width: 175px;
  height: 50px;
  margin: 0 auto;
  margin-top: 10px;

  background-color: black;
  color: white;

  border: solid;
  border-color:  var(--clr-blue);
  border-radius: 4px;

  font-family: "Play";
  font-size: 1.10em;
  font-weight: 600;

  cursor: pointer;
  transition: all 250ms ease;

  padding-top: 5px;
}

.nav__wallet:hover {
  background-color: white;
  color: var(--clr-blue);
}

.nav_button:hover {
  background-color: var(--clr-darker);
}

.logo{
  width: 40px;
  padding-bottom: 10px;
  padding-right: 5px;
}

/* Add media query for smaller screens */
@media (max-width: 768px) {
  nav {
    display: grid;
    justify-content: center; /* This centers the grid items within the grid container if there's extra space */
    align-items: center; /* This vertically aligns the grid items within their grid area */
    text-align: center; /* This centers the text or content inside each grid item */
    /* Additional styling if necessary */
  }
  .nav_links_sidebar {
    margin-top: 10px;
    flex-direction: column;
    padding-top: 60px;
    margin: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .nav_buttonlayout{
    display: none;
    
  
  }

  .nav_button {
    width: 200px;
    text-align: center;
    padding: 10px 16px;
    margin-top: 10px;
  }

  .nav__toggle {
    display: block;
  }

  .nav__toggleclose {
    display: block;
  }

  .nav__connect{
    display: none;
  }

  .sidebar_connect{
    width: 150px;
    height: 60px;
    margin: auto;
  
    color: white;
  
    background:
      linear-gradient(black 0 0) padding-box,
      linear-gradient(to top, white, var(--clr-blue)) border-box;
    border: 3px solid transparent;
    color: white;
    box-shadow: 0 0 0 1px var(--clr-blue);
    border-radius: 20px;
  
    font-family: "Play";
    font-size: 20px;
    font-weight: 600;
  
    cursor: pointer;
    transition: all 250ms ease;
    
    margin: auto;
    margin-bottom: 10px;
  }
}

/* Add media query for smaller screens */
@media (min-width: 768px) {
  

  .sidebar_connect{
    display: none;
  }
}

.nav__toggleclose {
  position: absolute;
  right: 0;
  width: 50px;
  padding: 5px;
  margin-right: 15px;
  font-size: 1.5em;
  background-color: var(--clr-blue);
  border: none;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 15px;
}

.nav__toggleclose:hover {
  background-color: var(--clr-darker);
}

.sidebar {
  position: fixed;
  top: 0;
  right: -250px;
  width: 250px;
  height: 100%;
  background-color: black;
  transition: right 0.3s;
  overflow-y: auto;
  border-left: white;
  border-width: 5px;
  border: white;
  border-color: white;
  border-left: 4px solid var(--clr-blue); 
}

.sidebar.open {
  right: 0;
}

/* ------------------------------------------------------ */
/* -- FORM -- */

.inputWithButton {
  position: relative;
  display: inline-block;
  background-color: white;
  margin-right: 10%;
  margin-left:10%;
  border-radius: 10px;
  text-align: right;
  padding-right:10px;
}

.maxButton {
  height: 100%;
  width:auto;
  background: white;
  border: none;
  padding-left:15px;
  margin-left: 10px;
  margin-right: 12%;
  font-size: 20px;
  font-family: 'play';

}

.form {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 600px;
}

.dropdown{
  background-color: var(--clr-dark);
  width: 250px;
}

.dropdownItem{
  background-color: var(--clr-dark);
}


.form {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: var(--clr-dark);

  margin: 0 25px
}


.claim {
  display: flex;
  flex-direction: column;
  background:
    linear-gradient(black 0 0) padding-box,
    linear-gradient(to top, white, var(--clr-blue)) border-box;
  border: 3px solid transparent;
  padding: 5px;
  color: white;
  box-shadow: 0 0 0 1px var(--clr-blue);
  border-radius: 25px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  max-width: 600px;
  min-width: 40%;
  margin: auto;
}

.mint_page {
  display: flex;
  flex-direction: column;
  background:
    linear-gradient(black 0 0) padding-box,
    linear-gradient(to top, white, var(--clr-blue)) border-box;
  border: 3px solid transparent;
  padding: 5px;
  color: white;
  box-shadow: 0 0 0 1px var(--clr-blue);
  border-radius: 25px;
  padding-bottom: 10px;
  width:max-content;
  max-width: 600px;
  margin: auto;
  cursor: pointer;
}

.mint_claim {
  display: flex;
  flex-direction: column;
  background:
    linear-gradient(black 0 0) padding-box,
    linear-gradient(to top, white, var(--clr-blue)) border-box;
  border: 3px solid transparent;
  padding: 5px;
  color: white;
  box-shadow: 0 0 0 1px var(--clr-blue);
  border-radius: 25px;
  padding-bottom: 10px;
  max-width: 600px;
  min-width: 40%;
  margin: auto;
}

.halloweenMint {
  display: flex;
  flex-direction: column;
  background:
    linear-gradient(green 0 0) padding-box,
    linear-gradient(to top, white, rgb(255, 0, 0)) border-box;
  border: 3px solid transparent;
  padding: 5px;
  color: white;
  box-shadow: 0 0 0 1px var(rgb(255, 0, 0));
  border-radius: 25px;
  padding-bottom: 10px;
  max-width: 600px;
  min-width: 40%;
  margin: auto;
  margin-bottom: 10px;
}

.userMint {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background:
    linear-gradient(black 0 0) padding-box,
    linear-gradient(to top, white, var(--clr-blue)) border-box;
  border: 3px solid transparent;
  padding: 5px;
  color: white;
  box-shadow: 0 0 0 1px var(--clr-blue);
  border-radius: 25px;
  padding-bottom: 10px;
  width: 80%;
  max-width: 600px;
  margin: auto;
  margin-bottom: 10px;
}

.dashboard {
  display: flex;
  flex-direction: column;
  background:
    linear-gradient(black 0 0) padding-box,
    linear-gradient(to top, white, var(--clr-blue)) border-box;
  border: 3px solid transparent;
  padding: 5px;
  color: white;
  box-shadow: 0 0 0 1px var(--clr-blue);
  border-radius: 25px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  border-spacing:10px;
  height: 100px;

}

.claimArea {
  display: grid;
  flex-direction: column;
  border-color: var(--clr-blue);
  color: var(--clr-dark);
  align-items: center;
  align-content: center;
  height: 400px;
}

.claimArea-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width:80%;
  justify-content: space-between;
  align-content: center;
  margin: auto; 
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 10px;
}

.text {
  display: flex;
  justify-content: flex-center;
  align-items: center;
  flex-direction: column;
  color: var(--clr-dark);

  margin: 5px;
  padding-top: 25px;
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 40px;
}

.text_create {
  display: flex;
  justify-content: flex-center;
  align-items: center;
  flex-direction: column;
  color: var(--clr-dark);

  margin: 5px;
  font-weight: bold;
  font-size: 16px;
}


.text-md {
  display: flex;
  justify-content: flex-center;
  align-items: center;
  flex-direction: column;
  color: var(--clr-blue);
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  padding-bottom: 20px;
}

.text-md-halloween {
  display: flex;
  justify-content: flex-center;
  align-items: center;
  flex-direction: column;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}


.padding {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 20px;
}

.text-transfer {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--clr-blue);
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin:auto;
  padding-left: 20px;
  padding-right: 20px;
}

.text-transfer-halloween {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin:auto;
  padding-left: 20px;
  padding-right: 20px;
}


.text-body {
  display: flex;
  justify-content: flex-center;
  align-items: center;
  flex-direction: column;
  color: var(--clr-dark);
  margin: 0px;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.text-header {
  display: flex;
  justify-content: flex-center;
  align-items: center;
  flex-direction: column;
  color: var(--clr-blue);
  margin: 0px;
  font-weight: bold;
  font-size: 30px;
  margin-top: 15px;
  margin-bottom: 5px
}

.text-present {
  display: flex;
  justify-content: flex-center;
  align-items: center;
  flex-direction: column;
  color: var(--clr-blue);
  margin: 0px;
  font-weight: bold;
  font-size: 30px;
  margin-top: 15px;
  margin-bottom: 5px;
  text-align: center;
}

.text-header-halloween {
  display: flex;
  justify-content: flex-center;
  align-items: center;
  flex-direction: column;
  color: rgb(255, 255, 255);
  margin: 0px;
  font-weight: bold;
  font-size: 30px;
  margin-top: 15px;
  margin-bottom: 5px
}

.dropzone-header {
  display: block;

  justify-content: flex-center;
  align-items: center;
  color: var(--clr-blue);
  margin: 0px;
  font-weight: bold;
  font-size: 30px;
  margin-top: 15px;
  margin-bottom: 5px
}

.dropzone-body {
  display: block;
  flex-direction: unset;
  justify-content: flex-center;
  align-items: center;
  color: var(--clr-dark);
  margin: 0px;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.header{
  color: var(--clr-blue);
  text-align:center;
  margin:auto;
  font-size: 32px;
  padding-bottom: 10px;
}

.header-halloween{
  color: rgb(255, 255, 255);
  text-align:center;
  margin:auto;
  font-size: 32px;
  padding-bottom: 10px;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);  /* 2x2 grid on larger screens */
  gap: 0.5rem;
  margin: auto;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.tokenSwapContainer{
  background:
    linear-gradient(black 0 0) padding-box,
    linear-gradient(to top, white, var(--clr-blue)) border-box;
  border: 3px solid transparent;
  box-shadow: 0 0 0 1px var(--clr-blue);
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
  padding: 5px;
}

.coinBuyCombo{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tokenInputField{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin-left: 5%;
  margin-right: 6%;
  border-radius: 10px;
  margin-bottom: 10px;
  font-family: 'play';
  font-size:20px;

}

.tokenText{
  color: white;
  text-align:left;
  margin-left: 7%;
  font-size: 20px;
}

.balanceText{
  color: white;
  font-size: 20px;
  margin-right: 10%;
}

.slippageContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slippageText{
  color: white;
  text-align:left;
  margin-left: 15%;
  font-size: 14px;
}

.slippageAmountText{
  color: white;
  text-align:right;
  margin-right: 15%;
  font-size: 14px;
}

.tokenTwoText{
  color: white;
  text-align:left;
  margin-left: 7%;
  font-size: 20px;
}

.tokenAmountField{
  background-color:white;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 10px;
  height: 30px;
  text-align: right;

}

.tokenAmountText{
  color: black;
  margin-right: 5%;
  line-height: 30px;
  font-size: 20px;
  width:auto;
  border: none;
  outline:none;

}

.claimButton {
  width: 175px;
  height: 50px;
  margin: auto;

  background:
    linear-gradient(black 0 0) padding-box,
    linear-gradient(to top, white, var(--clr-blue)) border-box;
  border: 3px solid transparent;
  color: white;
  box-shadow: 0 0 0 1px var(--clr-blue);
  border-radius: 20px;

  font-family: "Play";
  font-size: 20px;
  font-weight: bold;

  cursor: pointer;
  transition: all 250ms ease;
}

.claimButton:hover {
  background-color: white;
  color: var(--clr-blue);
  
}

.switchButton {
  width: auto;
  height: auto;
  margin: auto;
  background-color: black;
  border: none;  
  padding: 5px;
  color: var(--clr-blue);

  font-family: "Play";
  font-size: 40px;
  font-weight: 800;

  cursor: pointer;
  transition: all 250ms ease;

  align-items: center;
  text-align: center;
  justify-content: center;
  outline:none;

  margin-top: 5px;
}

.switchButton:hover {
  color: white;
  
}

.screenSwitchButton {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top:10px;
  font-size:20px;
  margin-bottom:10px;
}

.screenSwitch {
  width: 150px;
  height: 60px;
  margin: 0 auto;

  background:
    linear-gradient(black 0 0) padding-box,
    linear-gradient(to top, white, var(--clr-blue)) border-box;
  border: 3px solid transparent;
  padding: 5px;
  color: white;
  box-shadow: 0 0 0 1px var(--clr-blue);


  border-radius: 15px;

  font-family: "Play";
  font-size: 1.10em;
  font-weight: 600;

  cursor: pointer;
  transition: all 250ms ease;

  align-items: center;
  justify-content: center;
  text-align: center;

  display: flex;
  flex-direction: column;
}

.screenSwitch:hover {
  background-color: white;
  color: var(--clr-blue);
  
}

.claimButton:hover {
  background-color: white;
  color: var(--clr-blue);
  
}

.buyButton {
  width: 80%;
  height: 40px;
  margin: 0 auto;

  background:
    linear-gradient(black 0 0) padding-box,
    linear-gradient(to top, white, var(--clr-blue)) border-box;
  border: 3px solid transparent;
  padding: 5px;
  color: white;
  box-shadow: 0 0 0 1px var(--clr-blue);
  border-radius: 15px;

  font-family: "Play";
  font-size: 20px;
  font-weight: 600;

  cursor: pointer;
  transition: all 250ms ease;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buyButton:hover {
  background-color: white;
  color: var(--clr-blue);
  
}

.nft_input[type="text"] {
  width: 250px;
  height: 30px;
  margin: 10px 0;
  padding: 10px;
  color: var(--clr-dark);
  background-color: #202020;

  border: 1px solid var(--clr-dark);
  border-radius: 4px;

  font-size: 1.10em;
}

.input_create {
  width: 80%;
  height: 30px;
  margin: 10px 0;
  padding: 10px;
  color: black;
  background-color: white;
  justify-content: center;
  text-align:center;

  border: 1px solid var(--clr-blue);
  border-radius: 4px;

  font-size: 1.10em;
}

.dropzone{
  margin:auto;
}

.form_input[type="text"] {
  width: 250px;
  height: 50px;
  margin: 10px 0;
  padding: 10px;
  color: var(--clr-dark);
  background-color: #202020;

  border: 1px solid var(--clr-dark);
  border-radius: 4px;

  font-size: 1.10em;
}

.form_input[type="number"] {
  width: 250px;
  height: 50px;
  margin: 10px 0;
  padding: 10px;
  color: var(--clr-dark);
  background-color: #202020;

  border: 1px solid var(--clr-dark);
  border-radius: 4px;

  font-size: 1.10em;
}

form input[type="submit"] {
  background-color: var(--clr-dark);
  color: white;

  width: 250px;
  height: 50px;
  margin: 10px 0;
  padding: 10px;

  border: none;
  border-radius: 4px;

  font-family: "Play";
  font-size: 1.10em;
  font-weight: 400;

  cursor: pointer;
  transition: all 250ms ease;
}

form input[type="submit"]:hover {
  background-color: var(--clr-darker);
  color: var(--clr-white);
}

/* ------------------------------------------------------ */
/* -- IMAGE -- */

.image {
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 90%;
  width: 512px;
  height: 512px;
  margin: 0 25px;
  border: 3px solid var(--clr-dark);
  border-radius: 40px;

}

.image_minted {
  justify-content: center;
  align-items: center;
  flex-direction: center;
  padding: 10px;
  max-width: 90%;
  width: 512px;
  margin: 10px 10px 15px 15px;
  border: 3px solid var(--clr-dark);
  border-radius: 4px;

  overflow: hidden;
  text-align: center;
}

.image_img {
  width: 512px;
  height: 512px;
  border-radius: 6px;
}

.image__placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image__placeholder p {
  margin: 0 10px;
}

.image_layout {
  display: grid;
  grid-template-columns: (3, 1fr);
  padding:5px;
  align-items: center;
  justify-content: center;
  
}

.minted {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  outline: var(--clr-dark);
  outline-style: solid;
  color: var(--clr-dark);
  margin: 10px;
  border-radius: 25px;
  padding-top: 5px;
  padding-bottom: 10px;
  margin-right: 15%;
  margin-left: 15%;
  border-spacing:10px;
}

.dashboardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  width: 80%;
  margin:auto;

}

.video-mint {
  max-width: 80%;
  margin-right: 5%;
  margin-left: 5%;
  margin: auto;
  margin-bottom: 10px;
}

/* ------------------------------------------------------ */
/* -- TEXT -- */
p {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .form {
    flex-direction: column;
  }

  .info-grid {
    grid-template-columns: 1fr;  /* 1x4 grid on smaller screens */
  }
  
  .dashboard {
    height:50px;
    min-width: 400px;
    max-width: 400px;
    padding-top:10px;
    padding-bottom:20px;
  }
  .claim {
    margin: auto;
    margin-right: 5%;
    margin-left: 5%;
  }

  .video {
    max-width: 80%;
    margin-right: 5%;
    margin-left: 5%;
  }

  .video-mint {
    max-width: 80%;
    margin-right: 5%;
    margin-left: 5%;
    margin: auto;
    height: 200px;
  }


  .dashboardContainer {
    flex-wrap: wrap;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    margin:auto;
    align-items: center;
    justify-content: center;
    text-align:center;
    width: 80%;
  }

  .background {
    background-image: none;
  }
}



.gallery-text {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  margin-left: 10%;
  margin-right: 10%;
  color: white;
  font-size: 20px;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  flex-wrap: wrap;
  margin: 0 auto ;
  color: white;
  padding: 20px;
}

.nft-image {
  margin: 0 7.5px 15px;
  object-fit: cover;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  z-index: 1001;
  pointer-events: none; /* disable pointer events */
  
}

.modal_mint {
  position:fixed;
  top: 30px;
  bottom: 20px;
  margin: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  pointer-events: none; /* disable pointer events */
  text-align: center;
  overflow: auto;
}

.modal-content {
  background-color: black;
  max-width: 400px;
  margin: 0 auto;
  padding: 40px;
  border: 2px solid var(--clr-main);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 3px;
  background:
    linear-gradient(black 0 0) padding-box,
    linear-gradient(to top, white, var(--clr-blue)) border-box;
  border: 3px solid transparent;
  color: white;
  box-shadow: 0 0 0 1px var(--clr-blue);
  border-radius: 20px;
  pointer-events: auto; /* disable pointer events */
  padding-bottom: 50%;
  margin-bottom: 20px;
}

.modal-content_mint {
  background-color: black;
  max-width: 400px;
  margin: auto;
  padding-left: 40px;
  padding-right: 40px;
  border: 2px solid var(--clr-main);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 3px;
  background:
    linear-gradient(black 0 0) padding-box,
    linear-gradient(to top, white, var(--clr-blue)) border-box;
  border: 3px solid transparent;
  color: white;
  box-shadow: 0 0 0 1px var(--clr-blue);
  border-radius: 20px;
  pointer-events: auto; /* disable pointer events */
}

.modal-content img {
  max-width: 50vh;
}

.modal-content {
  background-color: black;
  max-width: 400px;
  margin: 0 auto;
  padding: 40px;
  border: 2px solid var(--clr-main);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 3px;
  background:
  linear-gradient(black 0 0) padding-box,
  linear-gradient(to top, white, var(--clr-blue)) border-box;
  border: 3px solid transparent;
  color: white;
  box-shadow: 0 0 0 1px orange;
  border-radius: 20px;
}

.nft-container {
  padding: 10px;
  border: none;
  background: none;
  cursor: pointer;
  border: 2px solid var(--clr-darker);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 400px;
  height: auto;
}

.nft-container img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.nft-container video {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}

.nav__toggle {
  width: 50px;
  padding: 5px;
  font-size: 1.5em;
  margin: auto;
  background-color: var(--clr-darker);
  border: blue;
  cursor: pointer;
  border-radius: 10px;
  justify-content: right;
  align-items: right;
  text-align: center;
}

.nav__toggleclose {
  position: absolute;
  right: 0;
  width: 50px;
  padding: 5px;
  margin-right: 15px;
  font-size: 1.5em;
  background-color: var(--clr-dark);
  border: none;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 15px;
  display:block;
}

.nav__toggleclose:hover {
  background-color: var(--clr-darker);
}


/* Add media query for smaller screens */
@media (max-width: 768px) {
  .nav_links {
    margin-top: 10px;
    flex-direction: column;
    padding-top: 60px;
  }

  .nav_button {
    width: 200px;
    text-align: center;
    padding: 10px 16px;
  }

  .nav__toggle {
    display: block;
  }

  .nav__toggleclose {
    display: block;
  }

  .claimArea-grid {
    flex-wrap: wrap;
    display: flex;
    grid-template-columns: repeat(1, 1fr);
    margin: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 80%;
}
}

.tokenAmountInput {
  background-color: white;
  font-size: 20px;
  color: black;  
  margin-left: 5%;
  margin-right: 10%;
  border-radius: 10px;
  height: 32px;
  text-align: right;
  padding-right:7%;
  border:none;
  width: 82%;
  outline: none;
  margin-bottom: 10px;
}

.tokenAmountInputTop {
  background-color: white;
  font-size: 20px;
  color: black;
  margin-right: 7%;
  border-radius: 10px;
  height: 30px;
  text-align: right;
  border:none;
  width: 50%;
  border:none;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
}

.tokenAmountInputTop[type="number"]::-webkit-inner-spin-button,
.tokenAmountInputTop[type="number"]::-webkit-outer-spin-button,
.tokenAmountInput[type="number"]::-webkit-inner-spin-button,
.tokenAmountInput[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



.stakeInput::placeholder {
  color: white;
  opacity: 1; /* Override browser default opacity */
}

.alert-button {
  position: fixed; /* Fix the button to a position */
  top: 0; /* Position it to the top of the screen */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%); /* This ensures the button is truly in the center */
  
  padding: 10px 20px; /* Padding around the text */
  background-color: blue; /* Background color */
  color: white; /* Text color */
  
  border: none; /* Removes default border */
  border-radius: 50px; /* Gives rounded corners. Adjust as necessary */
  
  font-size: 16px; /* Font size */
  cursor: pointer; /* Changes cursor on hover */
}

.alert {
  position: fixed; /* Fix the button to a position */
  top: 0; /* Position it to the top of the screen */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%); /* This ensures the button is truly in the center */
  
  padding: 10px 20px; /* Padding around the text */
  background-color: blue; /* Background color */
  color: white; /* Text color */
  
  border: none; /* Removes default border */
  border-radius: 50px; /* Gives rounded corners. Adjust as necessary */
  
  font-size: 16px; /* Font size */
  cursor: pointer; /* Changes cursor on hover */
}

/* AlertTemplate.css */
.alert-template {
  background-color: #f8b117;
  background: #f8b117;
  position: fixed; /* Fix the button to a position */
top: 0; /* Position it to the top of the screen */
left: 50%; /* Center it horizontally */
transform: translateX(-50%); /* This ensures the button is truly in the center */

padding: 10px 20px; /* Padding around the text */

border: none; /* Removes default border */
border-radius: 50px; /* Gives rounded corners. Adjust as necessary */

font-size: 16px; /* Font size */
cursor: pointer; /* Changes cursor on hover */
}

.alert-template span {
  /* Styles for the message text */
  background-color: #f8b117; /* same as the container background */
}

.totalArea_mint {
  width:fit-content;
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 2fr)); /* Adjust the minmax min value as needed */
  gap: 30px;
  row-gap: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 16px;
  margin: auto;
}

/* Adjustments for smaller screens */
@media screen and (max-width: 768px) {

  .totalArea_mint {
    flex-direction: column;
  }
}

@media (max-width: 599px) {
  .totalArea_mint {
    grid-template-columns: repeat(minmax(120px, 1fr));
    /* even smaller min-width for mobile screens */
  }
}