/* AlertTemplate.css */
.alert-template {
    background-color: white;
    position: fixed; /* Fix the button to a position */
  top: 0; /* Position it to the top of the screen */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%); /* This ensures the button is truly in the center */
  margin-top: 20px;
  
  padding: 10px 20px; /* Padding around the text */
  color: black; /* Text color */
  
  border: none; /* Removes default border */
  border-radius: 50px; /* Gives rounded corners. Adjust as necessary */
  
  font-size: 16px; /* Font size */
  cursor: pointer; /* Changes cursor on hover */
  }

  .Toastify__toast-body {
    background-color: white;
    color: black; /* This will be the toast text color */
    text-align: center;
    font-weight: bold;
    
}
  
  .alert-template span {
    /* Styles for the message text */
    background-color: #f8b117; /* same as the container background */
    color: white; /* choose a color that contrasts with the background */
  }
  
  .alert-template button {
    background-color: green;
    border-radius: 5px;
    cursor: pointer;
  }